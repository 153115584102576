<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Análise de Templates
        </p>
        <v-card
          flat
        >
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-autocomplete
                  v-model="filterUserId"
                  :items="users"
                  label="Usuário"
                  outlined
                  hide-details
                  dense
                  item-text="value"
                  item-value="id"
                  @change="fechSmsTemplates"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-autocomplete
                  v-model="filterStatus"
                  :items="smsTemplateStatus"
                  label="Status"
                  outlined
                  hide-details
                  dense
                  item-text="text"
                  item-value="value"
                  @change="fechSmsTemplates"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fechSmsTemplates"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            item-key="id"
            class="table-rounded"
            disable-sort
            dense
          >
            <template #[`item.action`]="{item}">
              <v-btn
                color="info"
                dark
                text
                title="Visualizar"
                x-small
                @click="openDialog(item)"
              >
                <v-icon>{{ icons.mdiEye }}</v-icon>
              </v-btn>
            </template>

            <template #[`item.smsTemplate.status`]="{item}">
              <v-chip
                class="my-2"
                :color="resolveStatus(item.smsTemplate.status).color"
                small
                label
              >
                <span class="white--text font-weight-semibold">
                  {{ resolveStatus(item.smsTemplate.status).value }}
                </span>
              </v-chip>
            </template>

            <template #[`item.smsTemplate.observation`]="{item}">
              <span v-if="item.smsTemplate.status === smsTemplate.Refused">
                {{ resolveObservation(item.smsTemplate.observation) }}
              </span>
            </template>

            <template #[`item.smsTemplate.createdAt`]="{item}">
              <span>{{ $day(item.smsTemplate.createdAt).format('DD/MM/YYYY HH:mm:ss') }}</span>
            </template>

            <template #[`item.smsTemplate.serviceIds`]="{item}">
              <v-chip
                v-for="(service, index) in item.smsTemplate.serviceIds"
                :key="index"
                class="ma-1"
                small
              >
                <span class="white--text font-weight-semibold primary--text">
                  {{ resolveServiceName(service) }}
                </span>
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogTemplate"
      :width="800"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ `SMS Template (#${form.id})` }}
          <v-spacer />
          <v-btn
            text
            x-small
            fab
            @click="dialogTemplate = false"
          >
            <v-icon color="error">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="form.name"
                outlined
                hide-details
                dense
                label="Nome"
                readonly
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="form.serviceIds"
                :items="smsTypes"
                :readonly="form.status !== smsTemplate.Pending"
                hide-details
                chips
                label="Serviços Associados"
                item-text="text"
                item-value="serviceId"
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    color="info"
                    label
                    small
                  >
                    {{ item.text }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="form.message"
                counter
                outlined
                dense
                hide-details
                label="Mensagem"
                readonly
                rows="8"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider v-if="smsTemplate.Pending === form.status" />
        <v-card-actions v-if="smsTemplate.Pending === form.status">
          <v-spacer />
          <v-btn
            color="error"
            outlined
            @click="dialogTemplateRefuse = true"
          >
            Recusar
          </v-btn>
          <v-btn
            color="success"
            @click="approveTemplate(form.id, form.serviceIds)"
          >
            Aprovar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <validation-observer
      ref="formRefuseTemplate"
      autocomplete="off"
      tag="form"
    >
      <v-dialog
        v-model="dialogTemplateRefuse"
        :width="400"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Recusar Template
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Motivo"
                  rules="required|max:300"
                  vid="formRefuse.observation"
                >
                  <v-textarea
                    v-model="formRefuse.observation"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    counter
                    outlined
                    dense
                    label="Motivo"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              outlined
              @click="dialogTemplateRefuse = false"
            >
              Fechar
            </v-btn>
            <v-btn
              color="error"
              @click="refuseTemplate()"
            >
              Recusar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiClose,
  mdiEye,
  mdiMagnify,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { smsTemplate, smsTemplateStatus, smsTypes } from '@/utils/enum'

export default {
  setup() {
    const users = ref([])
    const filterUserId = ref(null)
    const filterStatus = ref(null)
    const dialogTemplate = ref(false)
    const dialogTemplateRefuse = ref(false)
    const form = ref({
      id: ref(null),
      name: ref(null),
      serviceIds: ref([]),
      observation: ref(null),
      message: ref(null),
      status: ref(null),
    })

    const formRefuse = ref({
      id: ref(null),
      observation: ref(null),
    })

    const resolveStatus = status => {
      if (status === smsTemplate.Pending) return { color: 'info', value: 'Aguardando Análise' }
      if (status === smsTemplate.Approved) return { color: 'success', value: 'Aprovado' }

      return { color: 'error', value: 'Recusado' }
    }

    const resolveObservation = value => {
      const index = value.indexOf('motivo: ')

      if (index !== -1) {
        return value.substring(index)
      }

      return ''
    }

    const resolveServiceName = serviceId => {
      const service = smsTypes.find(smsType => smsType.serviceId === serviceId)

      if (service) {
        return service.text
      }

      return null
    }

    return {
      users,
      filterStatus,
      filterUserId,
      dialogTemplate,
      dialogTemplateRefuse,
      form,
      formRefuse,
      resolveStatus,
      resolveObservation,
      resolveServiceName,
      smsTemplate,
      smsTemplateStatus,
      smsTypes,
      icons: {
        mdiClose,
        mdiEye,
        mdiMagnify,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'ID', value: 'smsTemplate.id' },
        { text: 'Status', value: 'smsTemplate.status' },
        { text: 'Template', value: 'smsTemplate.name' },
        { text: 'Cód. Usuário', value: 'user.id' },
        { text: 'Usuário', value: 'user.name' },
        { text: 'Data Criação', value: 'smsTemplate.createdAt' },
        { text: 'Serviços Associados', value: 'smsTemplate.serviceIds' },
        { text: 'Observação', value: 'smsTemplate.observation', width: '500px' },
      ],
    }
  },

  computed: {
    ...mapState('reportSmsTemplates', [
      'rows',
    ]),
  },

  async created() {
    await this.findUsers()
    await this.fechSmsTemplates()
  },

  methods: {
    ...mapActions('reportSmsTemplates', [
      'find',
      'approve',
      'refuse',
    ]),
    ...mapActions('user', {
      fetchUsers: 'find',
    }),

    openDialog(row) {
      this.dialogTemplate = true
      this.dialogTemplateRefuse = false
      this.form = {
        id: row.smsTemplate.id,
        message: row.smsTemplate.message,
        name: row.smsTemplate.name,
        status: row.smsTemplate.status,
        serviceIds: row.smsTemplate.serviceIds,
      }
      this.formRefuse = {
        id: row.smsTemplate.id,
        observation: '',
      }
    },

    async findUsers() {
      const users = await this.fetchUsers()

      this.users = users.map(user => ({
        ...user,
        value: `${user.document ? String(this.$cpfCnpj.toMask(user.document)).concat(' - ', user.name) : user.name}`,
      }))
    },

    async fechSmsTemplates() {
      await this.find({
        userId: this.filterUserId,
        status: this.filterStatus,
      })
    },

    async fetchAction(isUpdate) {
      const body = {
        observation: this.form.observation,
      }

      if (await this.$refs.formSmsTemplate.validate()) {
        if (isUpdate) await this.update(body)
        else await this.create(body)

        this.fechSmsTemplates()
        this.dialogTemplate = false
      }
    },

    async approveTemplate(id, serviceIds) {
      if (serviceIds.length === 0) {
        this.$notify('Selecione ao menos um serviço.', 'error')

        return
      }

      const status = await this.approve({ id, serviceIds })

      if (status) {
        this.dialogTemplate = false
        this.fechSmsTemplates()
      }
    },

    async refuseTemplate() {
      const body = {
        id: this.formRefuse.id,
        observation: this.formRefuse.observation,
      }

      if (await this.$refs.formRefuseTemplate.validate()) {
        const status = await this.refuse(body)

        if (status) {
          this.dialogTemplateRefuse = false
          this.dialogTemplate = false
          this.fechSmsTemplates()
        }
      }
    },
  },
}

</script>
